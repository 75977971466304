class Pricing {
    Key = null

    Material = [
        {
            Key: "MS",
            Name: "Mild Steel",
            PricePerKg: 2.5,
            CuttingRates: [{t: 25, r: 4}],
            StandardSheet: {
                width: 3000,
                height: 1500,
            },
            Thicknesses: [0.5, 1, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12, 16, 20, 25],
            Density: 7900
        },
        {
            Key: "BIS",
            Name: "Bisalloy",
            PricePerKg: 6.6,
            CuttingRates: [{t: 25, r: 4}],
            StandardSheet: {
                width: 3000,
                height: 1500,
            },
            Thicknesses: [0.5, 1, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12, 16, 20, 25],
            Density: 7900
        },
        {
            Key: "SS304",
            Name: "Stainless Steel G304",
            PricePerKg: 7,
            CuttingRates: [{t: 6, r: 5},{t: 10, r: 7}, {t: 12, r: 8}],
            StandardSheet: {
                width: 2440,
                height: 1220,
            },
            Thicknesses: [0.5, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12],
            Density: 7902.78
        },
        {
            Key: "SS316",
            Name: "Stainless Steel G316",
            PricePerKg: 8,
            CuttingRates: [{t: 6, r: 5},{t: 10, r: 7}, {t: 12, r: 8}],
            StandardSheet: {
                width: 2440,
                height: 1220,
            },
            Thicknesses: [0.5, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12],
            Density: 7902.78
        },
        {
            Key: "AL",
            Name: "Aluminium",
            PricePerKg: 7,
            CuttingRates: [{t: 6, r: 5},{t: 10, r: 7}, {t: 12, r: 8}],
            StandardSheet: {
                width: 2400,
                height: 1200,
            },
            Thicknesses: [0.5, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12],
            Density: 2601.27
        }
    ]
    allThickness = ()=>{
        var thicknesses = [];
        const m = this.Material;
        for(let i=0; i<m.length; i++){
            for(let j=0; j<m[i].Thicknesses.length; j++){
                if(thicknesses.indexOf(m[i].Thicknesses[j])==-1){
                    thicknesses.push(m[i].Thicknesses[j]);
                }
            }
        }
        return thicknesses;
    }
    fullSheet = (thickness)=>{
        var key =  this.Key;
        if(key==null)return null;
        const m = this.Material;
        var material = null;
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                material = m[i];
            }
        }
        if(material!=null){
            var weight = ((material.StandardSheet.width/1000)*(material.StandardSheet.height/1000)*(thickness/1000))*material.Density;
            return {
                width: material.StandardSheet.width,
                height: material.StandardSheet.height,
                weight: weight,
                price: weight * material.PricePerKg

            }
        }
        return null;
    }
    activeMaterial = ()=>{
        var key =  this.Key;
        if(key==null)return null;
        const m = this.Material;
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                return m[i];
            }
        }
        return null;
    }
    setMaterial(key){
        const m = this.Material;
        if(key==null)return null;
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                this.Key = m[i].Key;
                return m[i];
            }
        }
        for(let i=0; i<m.length; i++){
            
            if(m[i].Key.substring(0,2)==key.substring(0,2)){
                this.Key = m[i].Key;
                return m[i];
            }
        }
        return null;
    }
    getMaterialCost(weight, utilisation, sheetArea, partArea){
        var key =  this.Key;
        if(key==null)return null;

        const m = this.Material; 
        //utilisation = (partArea/sheetArea)*100;
        
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                var cost = m[i].PricePerKg * weight;
                var util = utilisation * 1;
                //util = Math.ceil((util+1)/1)*1;
                //util = util>80?100:util;

                var nestCost = Math.ceil(((cost*(util/100)))/10)*10;
                return {sheet: cost, nest: nestCost, util: util, rate: m[i].PricePerKg};
            }
        }
        return null;
    }
    checkThickness(key, thickness){
        const m = this.Material;
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                for(let j=0; j<m[i].Thicknesses.length; j++){
                    //console.log(m[i].Thicknesses[j], thickness, m[i].Thicknesses[j] <= thickness)
                    if(m[i].Thicknesses[j] <= thickness){
                        return true;
                    }
                }
            }
        }
        return false;
    }
    getCuttingCost(thickness, time){
        var key =  this.Key;
        if(key==null)return null;

        const m = this.Material;
        for(let i=0; i<m.length; i++){
            if(m[i].Key==key){
                var material = m[i].CuttingRates;
                var rate = null;

                for(let j=0; j<material.length; j++){
                    if(thickness <= material[j].t){
                        rate = material[j];
                        break;
                    }
                }
                if(rate!=null){
                    time = time*1.1;
                    time = time < 5?5:time;
                    time = Math.ceil((time)/5)*5;
                    var cost = rate.r*time;
                    cost = Math.ceil((cost)/10)*10;
                    return {cost: cost, time: time, rate: rate.r};
                }
                
            }
        }
        return null;
    }
    processData = (data, override=false) =>{

        var matData = this.setMaterial(data.Material);

        
        if(matData!=null){


        data.MaterialName = "NA";
        data.Weight = 0;
        data.ThicknessDoesNotMatch = false;

        
            data.Material = this.Key;
            data.MaterialName = matData.Name;
            data.Weight = (data.SheetSizeX/1000) * (data.SheetSizeY/1000) * (data.Thickness/1000) * matData.Density;
            

           
            var clearance = data.Thickness;
            clearance = clearance < 10?10:data.Thickness;

            var bboxArea = 0;
            for(var i=0; i<data.Parts.length; i++){


                var l = (data.Parts[i].BoundingBoxX + (clearance*2))/1000;
                var w = (data.Parts[i].BoundingBoxY + (clearance*2))/1000;
                bboxArea += l*w*data.Parts[i].Quantity;
            }

            var sheetArea = (data.SheetSizeX/1000) * (data.SheetSizeY/1000);
            data.SheetArea = sheetArea;
            data.SheetUsage = sheetArea-data.LeftOverArea;

            data.OriginalSheetRatio = ((data.SheetUsage / sheetArea))*100;
            data.SheetRatioActual = data.OriginalSheetRatio;
            

            //First pass, set ratio to first pass
            if(typeof data.SheetRatio === 'undefined'){
                data.SheetRatio = data.OriginalSheetRatio;
                var usages = [];
                usages.push( data.OriginalSheetRatio);

                for(var i=0; i<=100; i+= 5){
                    if(i > data.OriginalSheetRatio){
                        usages.push(i)
                    }    
                }
                data.Usages = usages;
            }


            if(data.SheetRatio != data.OriginalSheetRatio){
                data.SheetUsage = data.SheetArea*(data.SheetRatio/100);
            }
            
            

            
            var mat = this.getMaterialCost(data.Weight,data.SheetRatio, sheetArea, bboxArea);
            var cut = this.getCuttingCost(data.Thickness,data.RunTime);
  
            if(cut!=null)
            {
                data.PricePerKg = mat.rate;
                data.PricePerMin = cut.rate;

                data.SheetUtilisation = data.SheetRatio;//mat.util;
                data.SheetCost = mat.sheet;
                data.AllowedRunTime = cut.time;

                if(data.Prediction && !override){
                    data.CostCutting = cut.cost;
                    data.CostMaterial = mat.nest;
                    data.CostHandling = 50;
                    data.CostHandling = data.TotalParts > 50?80:data.CostHandling;
                    data.CostHandling = data.TotalParts > 100?100:data.CostHandling;
                }
                else{
                    if(data.CostMaterial > 0){
                        var predictRatio = ((data.CostMaterial/data.Weight)/mat.rate)*100;
                        var minDx = 10000;
                        var closestUsage = 100;

                        for(var i=0; i<data.Usages.length; i++){
                            var dx = Math.abs(data.Usages[i]-predictRatio);
                            if(dx<minDx){
                                minDx = dx;
                                closestUsage = data.Usages[i];
                            }
                        }
                        data.SheetRatioActual =  predictRatio;
                        data.SheetRatio = closestUsage;
                        data.SheetUsage = data.SheetArea*(data.SheetRatio/100);  
                    }
                    if(data.CostCutting > 0){
                        data.AllowedRunTime = (data.CostCutting/cut.rate);
                    }
                }

            }
            


            data.CostTotal = data.CostCutting + data.CostMaterial + data.CostHandling;



            if(!override){
            data.CostCuttingString = data.CostCutting.toFixed(2);
            data.CostMaterialString = data.CostMaterial.toFixed(2);
            data.CostHandlingString = data.CostHandling.toFixed(2);
            }


            var totalPath = 0;
            var totalSurfaceAreaIgnoreHoles = 0;
            for(var i=0; i<data.Parts.length; i++){
                totalPath += (data.Parts[i].PathLengthTotal/1000)*data.Parts[i].Quantity;
                totalSurfaceAreaIgnoreHoles += (data.Parts[i].SurfaceAreaIgnoreHoles/1000000)*data.Parts[i].Quantity;
            }
            var thicknessDoesNotMatch = false;
            data.TotalPartWeight = 0;
            for(var i=0; i<data.Parts.length; i++){
                if(data.Thickness != data.Parts[i].Thickness){
                    thicknessDoesNotMatch = true;
                }
                data.Parts[i].cuttingPercent = ((data.Parts[i].PathLengthTotal/1000)/totalPath)*100;
                var partCutCost = data.CostCutting * (data.Parts[i].cuttingPercent/100);
                var partHandCost = data.CostHandling * (1/data.TotalParts);
                

  
                var lc = (data.Parts[i].BoundingBoxX + (clearance*2))/1000;
                var wc = (data.Parts[i].BoundingBoxY + (clearance*2))/1000;

                var l = (data.Parts[i].BoundingBoxX)/1000;
                var w = (data.Parts[i].BoundingBoxY)/1000;


                //var partMatCost = data.CostMaterial * ((lc*wc)/bboxArea);
                var partMatCost = data.CostMaterial * ((data.Parts[i].SurfaceAreaIgnoreHoles/1000000)/totalSurfaceAreaIgnoreHoles);
                


                data.Parts[i].Weight = ((data.Parts[i].SurfaceArea/1000000) *  (data.Thickness/1000))*matData.Density;
                data.TotalPartWeight += (data.Parts[i].Weight)*data.Parts[i].Quantity;

                data.Parts[i].CostCut = partCutCost;
                data.Parts[i].CostMat = partMatCost;
                data.Parts[i].CostHand = partHandCost;

                data.Parts[i].CostEach = partCutCost+partHandCost+partMatCost;
            }
            data.ThicknessDoesNotMatch = thicknessDoesNotMatch;
        }
        return data;
    }
    getJobNests(databaseRequest, JobId){
        return new Promise(async (resolve)=>{
        var data = [];
        var parts = await databaseRequest(`/nests/job/${JobId}`,"GET");
        var nests = [];

        for(let i=0; i<parts.length; i++){
            if(nests.indexOf(parts[i].NestId)==-1){
                nests.push(parts[i].NestId);
            }
        }   
        for(let i=0; i<nests.length; i++){
            var nest = await databaseRequest(`/nests/${nests[i]}`,"GET");
            nest = this.processData(nest);
            for(let j=0; j<nest.Parts.length; j++){
                for(let k=0; k<parts.length; k++){
                    if(nest.Parts[j].Id == parts[k].PartId){
                        nest.Parts[j].NumberSheets = nest.NumberSheets;
                        nest.Parts[j].Material = this.activeMaterial().Name;
                        nest.Parts[j].Thickness = nest.Thickness;
                        nest.Parts[j].State = nest.State;
                        data.push(nest.Parts[j]);
                    }
                }
            }
        }
        data = data.sort( ( a, b ) => {
            if ( a.CostEach < b.CostEach ){
            return -1;
            }
            if ( a.CostEach > b.CostEach ){
            return 1;
            }
            return 0;
        });
        data = data.sort( ( a, b ) => {
            if ( a.Name < b.Name ){
            return 1;
            }
            if ( a.Name > b.Name ){
            return -1;
            }
            return 0;
        });
        data = data.sort( ( a, b ) => {
            if ( a.Thickness < b.Thickness ){
            return -1;
            }
            if ( a.Thickness > b.Thickness ){
            return 1;
            }
            return 0;
        });

        data = data.sort( ( a, b ) => {
            if ( a.Material < b.Material ){
            return 1;
            }
            if ( a.Material > b.Material ){
            return -1;
            }
            return 0;
        });

        for(let i=0; i<data.length; i++){
            var found = false;
            var cheapest = data[i].CostEach;
            var cheapestId = data[i].Id;

            for(let j=0; j<data.length; j++){

                if(i!=j && data[i].Name==data[j].Name && data[i].Thickness==data[j].Thickness){
                    found=true;
                    if(data[j].CostEach < cheapest){
                        cheapest=data[j].CostEach;
                        cheapestId=data[j].Id;
                    }
                    if(data[j].CostEach == cheapest && data[j].Id < data[i].Id){
                        cheapest=data[j].CostEach;
                        cheapestId=data[j].Id;
                    }
                }
            }
            data[i].Duplicate = found;
            data[i].DuplicateDontShow = found && cheapestId!=data[i].Id; //dont show if duplicate found and this not cheapest
            data[i].DuplicateId = cheapestId;
        }

        for(let i=0; i<data.length; i++){
            data[i].ActualQty = data[i].Quantity*data[i].NumberSheets;
        }

        for(let i=0; i<data.length; i++){

            data[i].OrigQty = data[i].Quantity*data[i].NumberSheets;

            if(data[i].Duplicate && !data[i].DuplicateDontShow){

                //If duplicate and is shown
                for(let j=0; j<data.length; j++){
                    if(i!=j && data[i].Id==data[j].DuplicateId){
                        data[i].ActualQty += data[j].ActualQty;
                        data[j].ActualQty = 0;
                    }
                }

                /*for(let j=0; j<data.length; j++){
                    if(i!=j && data[j].Id==data[i].DuplicateId){
                        data[j].Quantity += data[i].Quantity*data[i].NumberSheets;
                    }
                }

                data[i].Quantity = 0;
                */
            }
        }
        resolve(data);
        });
    }
}

export default new Pricing();